
import { CartPartsItemInputCustom, CartItemInterface } from '~/operations';

type eventType = 'select_item' | 'view_item' | 'pageview' | 'add_to_cart' | 'remove_from_cart' | 'view_cart' | 'search' | 'begin_checkout' | 'purchase';


/**
 * data types input to exported function calls
 */

type Product = {
  id: string;
  image: string;
  name: string;
};

type BeginCheckout = {
  items?: (CartItemInterface | null)[];
};

type Purchase = {
  transaction_id: string;
  value: number;
  shipping: number;
  coupon?: string;
  items?: (CartItemInterface | null)[];
};


/**
 * param types to be input to ecommerce events
 */
type ProductParams = {
  item_id: string;
  item_name: string;
};

type CartParams = {
  item_id?: string;
  quantity?: number;
};

type SearchParams = {
  search_term: string;
};

/**
 * ecommerece event types
 */

type SelectItemType = {
  event: 'select_item';
  items: ProductParams[];
};

type ViewItemType = {
  event: 'view_item';
  items: ProductParams[];
};

type PageviewType = {
  event: 'pageview';
  page: string;
};

type AddToCartType = {
  event: 'add_to_cart';
  items: CartParams[];
};

type RemoveCartType = {
  event: 'remove_from_cart';
  items: CartParams[];
};

type ViewCartType = {
  event: 'view_cart';
  items: CartParams[];
};

type SearchType = {
  event: 'search';
  items: SearchParams;
};

type BeginCheckoutType = {
  event: 'begin_checkout';
  items: CartParams[];
};

type PurchaseType = {
  event: 'purchase';
  transaction_id: string;
  value: number;
  shipping: number;
  currency: string;
  coupon?: string;
  items: CartParams[];
};

type Ecommerce = SelectItemType | ViewItemType | PageviewType | AddToCartType | RemoveCartType | ViewCartType | SearchType | BeginCheckoutType | PurchaseType;

const sendEcommerceEvent = (payload: Ecommerce) => {
  const { event, ...rest } = payload;
  sendEvent(event, rest);
};

const sendEvent = (event: eventType, data: any) => {
  if (typeof window === 'undefined' || !window.gtag) return;
  window.gtag('event', event, data);
};

export const GA = {
  pageView(url: string) {
    sendEvent('pageview', { page: url });
  },
  ecommerce: {
    productClick(product: Product) {
      sendEcommerceEvent({
        event: 'select_item',
        items: [{
          item_id: product.name,
          item_name: product.name,
        }]
      });
    },
    productView(product: Product) {
      sendEcommerceEvent({
        event: 'view_item',
        items: [{
          item_id: product.name,
          item_name: product.name,
        }]
      });
    },
    addToCart(cartItems: CartPartsItemInputCustom[], itemNames: string[]) {
      sendEcommerceEvent({
        event: 'add_to_cart',
        items: cartItems.map((item, index) => ({ item_id: itemNames[index], quantity: item.quantity }))
      });
    },
    removeFromCart(ids: string[]) {
      sendEcommerceEvent({ event: 'remove_from_cart', items: ids.map(id => ({ item_id: id })) });
    },
    viewCart(ids: CartPartsItemInputCustom[], itemNames: string[]) {
      sendEcommerceEvent({
        event: 'view_cart',
        items: ids.map((item, index) => ({ item_id: itemNames[index], quantity: item.quantity }))
      });
    },
    searchProduct(searchText: string) {
      sendEcommerceEvent({
        event: 'search',
        items: {
          search_term: searchText,
        }
      });
    },
    searchCar(searchText: string) {
      sendEcommerceEvent({
        event: 'search',
        items: {
          search_term: searchText,
        }
      });
    },
    beginCheckout(purchase: BeginCheckout) {
      sendEcommerceEvent({
        event: 'begin_checkout',
        items: purchase.items ? purchase.items.map(item => ({ item_id: item?.catalogName || '', quantity: item?.quantity || 0 })) : [],
      });
    },
    transaction(purchase: Purchase) {
      sendEcommerceEvent({
        event: 'purchase',
        transaction_id: purchase.transaction_id,
        value: purchase.value,
        shipping: purchase.shipping,
        currency: 'BRL',
        coupon: purchase.coupon,
        items: purchase.items ? purchase.items.map(item => ({ item_id: item?.catalogName || '', quantity: item?.quantity || 0 })) : [],
      });
    },
  },
};

export default GA.ecommerce;
