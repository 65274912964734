import { Modal, Typography } from '@objectedge/pecai-storefront-ds';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CheckCircle } from '~/components/icons';
import { BackofficeBuyer } from '~/operations';
import { Breakpoint, useBreakpoint } from '~/utils/display';
import ModalCustomerRegistrationForm from './ModalCustomerRegistrationForm';
import styles from './RegisterModal.module.scss';

interface RegisterModalProps {
  isOpen: boolean;
  cancelHandler: () => void;
  handleGoToLoginModal: () => void;
}

const RegisterModal: React.FC<RegisterModalProps> = ({ isOpen, cancelHandler, handleGoToLoginModal }) => {
  const [registerForm, setRegisterForm] = useState<BackofficeBuyer>();

  const isMiniMobileView = useBreakpoint({ max: Breakpoint.xs });
  const isMobileView = useBreakpoint({ max: Breakpoint.md });

  return !registerForm ? (
    <Modal
      isOpen={isOpen}
      cancelHandler={cancelHandler}
      backdrop="static"
      okHandler={() => null}
      showOkButton={false}
      showModalHeader={true}
      showCancelButton={false}
      title={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Typography
            style={{ fontFamily: 'Montserrat' }}
            variant={!isMobileView ? 'h3' : isMiniMobileView ? 'body-xsmall' : 'body-large'}
            color="primary-900"
            weight="bold"
          >
            Faça o cadastro no&nbsp;
          </Typography>
          <Typography
            style={{ fontFamily: 'Montserrat' }}
            variant={!isMobileView ? 'h3' : isMiniMobileView ? 'body-xsmall' : 'body-large'}
            color="primary-500"
            weight="bold"
          >
            Peça Aí
          </Typography>
        </div>
      }
      size="lg"
      contentStyle={styles.modalContent}
      modalProps={{ centered: true }}
    >
      <ModalCustomerRegistrationForm
        setRegisterForm={setRegisterForm}
        handleGoToLoginModal={handleGoToLoginModal}
        handleCloseModal={cancelHandler}
      />
    </Modal>
  ) : (
    <Modal
      isOpen={isOpen}
      cancelHandler={cancelHandler}
      okHandler={cancelHandler}
      showOkButton={false}
      showModalHeader={true}
      showCancelButton={false}
      size="lg"
      modalProps={{ centered: true }}
    >
      <div className={styles.confirmation}>
        <Typography
          className={styles.header}
          variant={!isMobileView ? 'h2' : isMiniMobileView ? 'body-xsmall' : 'body-large'}
        >
          <CheckCircle />
          Cadastro Finalizado
        </Typography>
        <Button onClick={handleGoToLoginModal} block color="primary" className="mt-5">
          Faça seu login
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterModal;
