import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
export var createImage = function(url) {
    return new Promise(function(resolve, reject) {
        var image = new Image();
        image.addEventListener("load", function() {
            return resolve(image);
        });
        image.addEventListener("error", function(error) {
            return reject(error);
        });
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
    });
};
function getRadianAngle(degreeValue) {
    return degreeValue * Math.PI / 180;
}
export default function getCroppedImg(params) {
    return _getCroppedImg.apply(this, arguments);
}
function _getCroppedImg() {
    _getCroppedImg = _async_to_generator(function(params) {
        var imageSrc, pixelCrop, _base64, base64, _rotation, rotation, _mimeType, mimeType, image, canvas, ctx, maxSize, safeArea, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    imageSrc = params.imageSrc, pixelCrop = params.pixelCrop, _base64 = params.base64, base64 = _base64 === void 0 ? false : _base64, _rotation = params.rotation, rotation = _rotation === void 0 ? 0 : _rotation, _mimeType = params.mimeType, mimeType = _mimeType === void 0 ? "image/jpeg" : _mimeType;
                    return [
                        4,
                        createImage(imageSrc)
                    ];
                case 1:
                    image = _state.sent();
                    canvas = document.createElement("canvas");
                    ctx = canvas.getContext("2d");
                    maxSize = Math.max(image.width, image.height);
                    safeArea = 2 * (maxSize / 2 * Math.sqrt(2));
                    canvas.width = safeArea;
                    canvas.height = safeArea;
                    ctx.translate(safeArea / 2, safeArea / 2);
                    ctx.rotate(getRadianAngle(rotation));
                    ctx.translate(-safeArea / 2, -safeArea / 2);
                    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
                    data = ctx.getImageData(0, 0, safeArea, safeArea);
                    canvas.width = pixelCrop.width;
                    canvas.height = pixelCrop.height;
                    ctx.putImageData(data, Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x), Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y));
                    if (base64) return [
                        2,
                        canvas.toDataURL(mimeType)
                    ];
                    return [
                        2,
                        new Promise(function(resolve) {
                            canvas.toBlob(function(file) {
                                resolve(URL.createObjectURL(file));
                            }, mimeType);
                        })
                    ];
            }
        });
    });
    return _getCroppedImg.apply(this, arguments);
}
