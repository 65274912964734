import { ApolloLink, InMemoryCache } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import cookie from 'js-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  PartnerPartsCartDocument,
  GetLoggedProviderDocument,
  GetLoggedUserDocument,
  CartFragmentFragmentDoc,
} from '~/operations';
import { getAuthCookie } from '~/utils/cookie';

let ErrorBoundary;
let apolloCache: InMemoryCache;
let bugsnagLoaded = false;

if (process.env.BUGSNAG_API_KEY && process.browser && !(Bugsnag as any)._client) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
      const user: any = {
        vehicle: getFromLocalStorage('nn.selected-vehicle'),
        providerId: cookie.get(process.env.COOKIE_PROVIDER_ID as string),
      };

      const token = getAuthCookie();
      user.jwt = token ? jwtDecode<JwtPayload>(token) : undefined;

      if (apolloCache) {
        user.loggedProvider = getFromQuery(GetLoggedProviderDocument, {
          userId: user.jwt?.sub,
          providerId: user.providerId,
          paginationProvidersRequest: { size: 6 },
        })?.provider;

        user.loggedUser = getFromQuery(GetLoggedUserDocument, {
          userId: user.jwt?.sub,
          paginationProvidersRequest: { size: 6 },
        });
      }

      event.addMetadata('user', user);
      event.addMetadata('cart', {
        id: getFromLocalStorage('nn.cartId'),
        cart: getFromLocalStorage('nn.cart'),
      });
    },
    onBreadcrumb: function (breadcrumb) {
      if (
        breadcrumb.type === 'request' &&
        (breadcrumb.metadata?.request?.includes('/graphql') || breadcrumb.metadata?.request?.includes('/_next'))
      ) {
        return false;
      }
    },
  });

  bugsnagLoaded = true;

  // Removing for the moment
  // ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary();
}

const getFromQuery = (query, variables = {}) => {
  try {
    return apolloCache.readQuery<any>({ query, variables });
  } catch (e) {
    return null;
  }
};

const getFromLocalStorage = (id) => {
  try {
    return JSON.parse(localStorage.getItem(id) || '{}');
  } catch (e) {
    return null;
  }
};

const BugsnagErrorBoundary = ({ children }) => (ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : children);

const getGraphQLData = ({ operationName, variables }) => ({
  operationName,
  variables: operationName === 'CreateCreditCardToken' ? '***REDACTED***' : variables || undefined,
});

export const bugsnagMiddleware = new ApolloLink((operation, forward) => {
  if (!process.env.BUGSNAG_API_KEY) return forward(operation);

  // else
  apolloCache = operation.getContext().cache;

  bugsnagLoaded && Bugsnag.leaveBreadcrumb('GraphQL Operation', getGraphQLData(operation), 'request');

  return forward(operation).map((data) => {
    if (data?.errors) {
      bugsnagLoaded &&
        Bugsnag.leaveBreadcrumb(
          'GraphQL Operation Error',
          { ...getGraphQLData(operation), errors: data.errors.map(({ path, message }) => ({ path, message })) },
          'error'
        );
    }

    return data;
  });
});

export default BugsnagErrorBoundary;
