import clsx from 'clsx';
import React from 'react';
import { Typography } from '@objectedge/pecai-storefront-ds';
import styles from './Loader.module.scss';
import { BrandIcon } from '~/components/icons';

export interface LoaderProps {
  message?: string;
  full?: boolean;
  constrained?: boolean;
  className?: string;
}

export const Loader = (props: LoaderProps): JSX.Element => {
  const { message = 'Aguarde um momento...', full, constrained, className } = props;

  return (
    <div
      className={clsx(styles['loader-container'], full && styles.full, constrained && styles.constrained, className)}
    >
      <div className={styles['loader-content']}>
        <div className={styles['loader-logotype']}>
          <BrandIcon />
        </div>

        <Typography variant="h6" className={styles['loader-message']}>
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default Loader;
