import Yup from './yup';

export const maskEmail = (email: string) =>
  email.replace(/^(.{2})(.*?)(@.+)$/, (...args) => args[1] + args[2].replace(/./g, '*') + args[3]);

/**
 * Validation rules for customer record
 */
export const validation = {
  email: Yup.string().email('Email inválido').required('Campo é obrigatório'),

  getSchema(props = {}) {
    const schema = {};
    Object.entries(props).forEach(([key, value]) => {
      if (this === undefined || this[key] === undefined) return;
      if (typeof value === 'string') schema[value] = this[key];
      else schema[key] = this[key];
    });

    return this.shape(schema);
  },

  shape(schema) {
    return Yup.object().shape(schema);
  },
};
