export var colors = {
    dark: "#5c656f",
    darkHover: "#7d8895",
    white: "#ffffff",
    black: "#000000",
    grey: "#a6b0bb",
    greyPressed: "#939ba4",
    greyHover: "#c3ceda",
    lightGrey: "#dee3e8",
    light: "#f7f8f9",
    lightHover: "#f9f9f9",
    lightPressed: "#e3e3e3",
    info: "#1698af",
    infoPressed: "#137c8e",
    infoHover: "#23bdd8",
    links: "#3184e3",
    linkPressed: "#296fbf",
    linksHover: "#3e96fa",
    warning: "#ffc107",
    warningPressed: "#e2ac08",
    warningHover: "#ffd145",
    danger: "#d90d3e",
    dangerPressed: "#bc0934",
    dangerHover: "#ef325f",
    success: "#1ab963",
    successPressed: "#138b4a",
    successHover: "#22d775",
    secondary: "#333237",
    secondaryPressed: "#272629",
    secondaryHover: "#57565c",
    default: "#333237",
    defaultPressed: "#272629",
    defaultHover: "#57565c",
    primary: "#f49d29",
    primaryLight: "#fef5e9",
    primaryPressed: "#d8810d",
    primaryHover: "#ffb95b",
    accent: "#bb6bd9"
};
export default colors;
