interface ProductData {
  id?: string; // The product ID or SKU (e.g. P67890).
  name?: string; // The name of the product (e.g. Android T-Shirt).
  brand?: string; // The brand associated with the product (e.g. Google).
  category?: string; // he category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
  variant?: string; // The variant of the product (e.g. Black).
  price?: number; // The price of a product (e.g. 29.20).
  quantity?: number; // The quantity of a product (e.g. 2).
  coupon?: string; // The coupon code associated with a product (e.g. SUMMER_SALE13).
  position?: number; // The product's position in a list or collection (e.g. 2).
  partner?: string; // The product's provider company name.
}

interface ImpressionData {
  id?: string; // The product ID or SKU (e.g. P67890).
  name?: string; // The name of the product (e.g. Android T-Shirt).
  list?: string; // The list or collection to which the product belongs (e.g. Search Results)
  brand?: string; // The brand associated with the product (e.g. Google).
  category?: string; // he category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
  variant?: string; // The variant of the product (e.g. Black).
  position?: number; // The product's position in a list or collection (e.g. 2).
  price?: number; // The price of a product (e.g. 29.20).
}

interface ActionData {
  id?: string; // The transaction ID (e.g. T1234).
  affiliation?: string; // The store or affiliation from which this transaction occurred (e.g. Google Store).
  revenue?: number; // Specifies the total revenue or grand total associated with the transaction (e.g. 11.99). This value may include shipping, tax costs, or other adjustments to total revenue that you want to include as part of your revenue calculations.
  tax?: number; // The total tax associated with the transaction.
  shipping?: number; // The shipping cost associated with the transaction.
  coupon?: string; // The transaction coupon redeemed with the transaction.
  list?: string; // The list that the associated products belong to. Optional.
  step?: number; // A number representing a step in the checkout process. Optional on `checkout` actions.
  option?: string; // Additional field for checkout and checkout_option actions that can describe option information on the checkout page, like selected payment method.
}

interface EcommerceData {
  currencyCode?: string;
  add?: { products: Array<ProductData> };
  remove?: { products: Array<ProductData> };
  click?: { actionField?: ActionData; products: Array<ProductData> };
  checkout?: { actionField?: ActionData; products: Array<ProductData> };
  purchase?: { actionField?: ActionData; products: Array<ProductData> };
}

type GTMEvent = 'pageview' | 'addToCart' | 'removeFromCart' | 'checkout' | 'transaction' | 'productClick';

const sendEcommerceEvent = (event: GTMEvent, ecommerce: EcommerceData) => {
  sendEvent(event, { ecommerce });
};

const sendEvent = (event: GTMEvent, data) => {
  if (typeof window === 'undefined' || !window.dataLayer) return;
  window.dataLayer.push({ event, ...data });
};

export const GTM = {
  pageView(url: string) {
    sendEvent('pageview', { page: url });
  },
  ecommerce: {
    // ... TODO
  },
};

export default GTM.ecommerce;
