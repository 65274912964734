export const FastDeliveryIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="white" fillOpacity="0.1" />
    <path d="M20.5 19H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.5 23L15 23" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.5 27L18 27" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M35 16H29.8833C29.5575 16 29.2522 16.1587 29.065 16.4252L24 23.6364V27C24 27.5523 24.4477 28 25 28H26.7273"
      stroke="#F49D29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="30.5" cy="28.5" r="2.75" stroke="#F49D29" strokeWidth="1.5" />
    <defs>
      <linearGradient
        id="paint0_linear_103_6415"
        x1="720"
        y1="176.941"
        x2="720"
        y2="777"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#333237" />
        <stop offset="1" stopColor="#212025" />
      </linearGradient>
    </defs>
  </svg>
);
