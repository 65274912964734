export const Star3Icon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 3L19.3063 10.9394L27.8882 11.6266L21.3497 17.2205L23.3473 25.5846L16 21.1025L8.65268 25.5846L10.6503 17.2205L4.11179 11.6266L12.6937 10.9394L16 3Z"
      fill="#F9F9F7"
      stroke="#333237"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="4.87682"
      y2="-0.5"
      transform="matrix(0.707546 -0.706667 0.707546 0.706667 21.4349 8.9707)"
      stroke="#F49D29"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="4.87682"
      y2="-0.5"
      transform="matrix(-0.707546 -0.706667 -0.707546 0.706667 10.5652 8.9707)"
      stroke="#F49D29"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="4.87347"
      y2="-0.5"
      transform="matrix(-7.11194e-08 -1 -1 2.68659e-08 15.7288 29)"
      stroke="#F49D29"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="4.87897"
      y2="-0.5"
      transform="matrix(0.906509 -0.422187 0.42305 0.906106 3.5 20.3699)"
      stroke="#F49D29"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="4.87897"
      y2="-0.5"
      transform="matrix(-0.906509 -0.422186 -0.42305 0.906106 28.4844 20.3699)"
      stroke="#F49D29"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
