import React, { useContext } from 'react';
import { Typography, Input, Button, Form } from '@objectedge/pecai-storefront-ds';
import styles from './LoginForm.module.scss';
import Yup from '~/utils/yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { AuthenticationContext } from '~/components/context/Authentication';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';

const schema = Yup.object().shape({
  email: Yup.string().trim().email('Email inválido').required('Campo é obrigatório'),
  password: Yup.string().trim().required('Campo é obrigatório'),
});

interface LoginFormProps {
  changeFormState: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ changeFormState }) => {
  const { login, loggingIn } = useContext(AuthenticationContext);
  const { handleSubmit, errors, control, setError } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schema),
  });
  const { handleGoToRegisterModal } = useLoginAndRegisterModal();

  const onSubmit = async ({ email, password }) => {
    try {
      await login(email, password);
    } catch (e) {
      let errorMessage = 'Senha incorreta.';
      if (e instanceof Error && e.message.includes('disabled temporarily')) {
        errorMessage = `${errorMessage} Tente redefinir a sua senha, clicando no link esqueci minha senha.`;
      }
      setError('password', { type: 'custom', message: errorMessage });
    }
  };

  return (
    <>
      <Form className={styles.formContent} onSubmit={handleSubmit(onSubmit)}>
        <label className={styles.inputLabel} htmlFor="email">
          <Typography variant="button-large" color="primary-900" weight="bold">
            E-mail
          </Typography>
        </label>
        <Controller
          control={control}
          as={Input}
          name="email"
          label="Digite seu e-mail"
          className={styles.emailInput}
          status={errors.email ? 'danger' : 'default'}
          helperText={errors.email?.message ?? ''}
          data-qa="login-email"
        />
        <label className={styles.inputLabel} htmlFor="password">
          <Typography variant="button-large" color="primary-900" weight="bold">
            Senha
          </Typography>
        </label>
        <Controller
          control={control}
          as={Input}
          name="password"
          type="password"
          label="Digite sua senha"
          className={styles.passwordInput}
          status={errors.password ? 'danger' : 'default'}
          helperText={errors.password?.message ?? ''}
          data-qa="login-password"
        />

        <Button type="submit" block disabled={loggingIn} data-qa="login-button">
          Entrar
        </Button>
      </Form>
      <Button color="light" className={styles.forgotPass} outline onClick={changeFormState}>
        Esqueci minha senha
      </Button>
      <Button color="light" className={styles.forgotPass} outline onClick={handleGoToRegisterModal}>
        Cadastre-se
      </Button>
    </>
  );
};

export default LoginForm;
