export const WhatsappCtaIcon = (props) => (
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7489_1036)">
            <path d="M1.36578 31.617C1.36428 36.9942 2.78028 42.2447 5.47278 46.8726L1.10828 62.6845L17.4163 58.4417C21.9268 60.8782 26.9806 62.1548 32.1163 62.1552H32.1298C49.0835 62.1552 62.8843 48.4664 62.8915 31.6411C62.8948 23.488 59.6978 15.8214 53.8893 10.0535C48.0818 4.28601 40.358 1.10809 32.1285 1.10437C15.1728 1.10437 1.37303 14.7924 1.36603 31.617" fill="url(#paint0_linear_7489_1036)" />
            <path d="M0.2675 31.6071C0.26575 37.1778 1.7325 42.6161 4.521 47.4096L0 63.7884L16.8927 59.3935C21.5472 61.9116 26.7878 63.2392 32.1203 63.2412H32.134C49.696 63.2412 63.9925 49.06 64 31.6324C64.003 23.1864 60.691 15.2442 54.675 9.26958C48.6582 3.29575 40.6582 0.00347287 32.134 0C14.569 0 0.2745 14.1792 0.2675 31.6071ZM10.3275 46.5841L9.69675 45.5906C7.04525 41.4073 5.64575 36.573 5.64775 31.6091C5.6535 17.1235 17.5345 5.33829 32.144 5.33829C39.219 5.34127 45.868 8.07789 50.869 13.0431C55.8697 18.0088 58.6215 24.6097 58.6198 31.6304C58.6133 46.116 46.732 57.9026 32.134 57.9026H32.1235C27.3703 57.9002 22.7085 56.6335 18.643 54.24L17.6755 53.6707L7.651 56.2786L10.3275 46.5838V46.5841Z" fill="url(#paint1_linear_7489_1036)" />
            <path d="M24.1695 18.3933C23.573 17.0778 22.9453 17.0513 22.378 17.0282C21.9135 17.0084 21.3825 17.0099 20.852 17.0099C20.321 17.0099 19.4583 17.2081 18.729 17.9981C17.999 18.789 15.942 20.7 15.942 24.5869C15.942 28.474 18.7953 32.2304 19.193 32.7581C19.5913 33.2847 24.7013 41.5164 32.7943 44.6832C39.5203 47.3148 40.889 46.7914 42.3488 46.6595C43.8088 46.528 47.0598 44.7489 47.723 42.9041C48.3868 41.0595 48.3868 39.4783 48.1878 39.1479C47.9888 38.8187 47.4578 38.621 46.6615 38.2261C45.865 37.8309 41.9505 35.9196 41.2208 35.6559C40.4908 35.3925 39.96 35.261 39.429 36.0521C38.898 36.8419 37.3733 38.621 36.9085 39.1479C36.4443 39.676 35.9795 39.7418 35.1835 39.3466C34.3868 38.9502 31.8225 38.117 28.7803 35.4257C26.4133 33.3316 24.8153 30.7455 24.3508 29.9545C23.8863 29.1646 24.301 28.7365 24.7003 28.3428C25.058 27.9888 25.4968 27.4203 25.8953 26.9591C26.2923 26.4977 26.4248 26.1685 26.6903 25.6417C26.956 25.1143 26.823 24.6529 26.6243 24.2577C26.4248 23.8626 24.8775 19.9553 24.1695 18.3933Z" fill="white" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_7489_1036" x1="3090.27" y1="6159.12" x2="3090.27" y2="1.10437" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1FAF38" />
                <stop offset="1" stopColor="#60D669" />
            </linearGradient>
            <linearGradient id="paint1_linear_7489_1036" x1="3200" y1="6378.84" x2="3200" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F9F9F9" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <clipPath id="clip0_7489_1036">
                <rect width="64" height="64" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default WhatsappCtaIcon;


