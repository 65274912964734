import React, { useState } from 'react';
import { Modal, Typography } from '@objectedge/pecai-storefront-ds';
import styles from './LoginModal.module.scss';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';
import { Breakpoint, useBreakpoint } from '~/utils/display';

type LoginModalProps = {
  isOpen: boolean;
  cancelHandler: () => void;
};

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, cancelHandler }) => {
  const [loginForm, setLoginForm] = useState<boolean>(true);
  const [successLayout, setSuccessLayout] = useState<boolean>(false);
  const isMobileView = useBreakpoint({ max: Breakpoint.md });

  const handleFormChange = () => {
    setLoginForm(!loginForm);
  };

  const handleSuccessLayout = () => {
    setSuccessLayout(!successLayout);
  };

  return (
    <Modal
      isOpen={isOpen}
      cancelHandler={cancelHandler}
      okHandler={() => null}
      showOkButton={false}
      showModalHeader={true}
      showCancelButton={false}
      title={
        loginForm ? (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Typography variant={isMobileView ? 'h4' : 'display-3'} mobileClass="h6" color="primary-900" weight="bold">
              Faça seu login no&nbsp;
            </Typography>
            <Typography variant={isMobileView ? 'h4' : 'display-3'} mobileClass="h6" color="primary-500" weight="bold">
              Peça Aí
            </Typography>
          </div>
        ) : !successLayout ? (
          'Esqueci minha senha'
        ) : (
          'Recuperação enviada!'
        )
      }
      size="lg"
      contentStyle={styles.modalContent}
      modalProps={{ centered: true }}
    >
      {loginForm ? (
        <LoginForm changeFormState={handleFormChange} />
      ) : (
        <ResetPasswordForm changeFormState={handleFormChange} handleSuccessLayout={handleSuccessLayout} />
      )}
    </Modal>
  );
};

export { LoginModal };
