import cardValidator from 'card-validator';
import * as Yup from 'yup';

Yup.addMethod<Yup.StringSchema>(Yup.string, 'carPlate', function (message?: string) {
  return this.test(
    'carPlate',
    message || '',
    (value) => !value || !!value.replace('_', '').match(/^[a-z]{3}-\d[a-z0-9]\d{2}$/i)
  );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phoneNumber', function (message?: string) {
  return this.test(
    'phoneNumber',
    message || '',
    (value) =>
      !value ||
      !!value
        .replace(/[^\d]/g, '')
        .match(/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/)
  );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'postalCode', function (message?: string) {
  return this.test('postalCode', message || '', (value) => {
    return !value || !!value.replace('_', '').match(/^([0-9]{8}|\d{5}-\d{3})?$/);
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'hasMoreOrEqualThanEightCaracters', function (message?: string) {
  return this.test('hasMoreOrEqualThanEightCaracters', message || '', (value) => !value || !!value.match(/^.{8,}$/));
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'hasMoreOrEqualThanSixCaracters', function (message?: string) {
  return this.test('hasMoreOrEqualThanSixCaracters', message || '', (value) => !value || !!value.match(/^.{6,}$/));
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'hasLowerCase', function (message?: string) {
  return this.test('hasLowerCase', message || '', (value) => !value || !!value.match(/[a-z]/));
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'hasUpperCase', function (message?: string) {
  return this.test('hasUpperCase', message || '', (value) => !value || !!value.match(/[A-Z]/));
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'hasNumber', function (message?: string) {
  return this.test('hasNumber', message || '', (value) => !value || !!value.match(/[0-9]/));
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'hasSpecialCaracters', function (message?: string) {
  return this.test(
    'hasSpecialCaracters',
    message || '',
    (value) => !value || !!value.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)
  );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'cpf', function (message?: string) {
  return this.test('cpf', message || '', (value) => {
    if (!value) return true;

    const number = value.replace(/[^\d]/g, '');
    if (number.length !== 11) return false;
    if (number.match(/\b(\d)\1+\b/)) return false; // repeating numbers

    try {
      let v1 = 0,
        v2 = 0;

      for (let i = 0, p = 10; number.length - 2 > i; i++, p--) v1 += parseInt(number[i]) * p;
      v1 = (v1 * 10) % 11;
      if (v1 === 10) v1 = 0;
      if (v1 !== Number(number[9])) return false;
      for (let i = 0, p = 11; number.length - 1 > i; i++, p--) v2 += parseInt(number[i]) * p;
      v2 = (v2 * 10) % 11;
      if (v2 === 10) v2 = 0;

      return v2 === Number(number[10]);
    } catch (e) {
      return false;
    }
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'cnpj', function (message?: string) {
  return this.test('cnpj', message || '', (value) => {
    if (!value) return true;

    const cnpj = (value || '').replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) return false;
    if (/^(\d)\1+$/.test(cnpj)) return false;

    const t = cnpj.length - 2,
      d = cnpj.substring(t),
      d1 = parseInt(d.charAt(0)),
      d2 = parseInt(d.charAt(1)),
      calc = (x: number) => {
        let n = cnpj.substring(0, x),
          y = x - 7,
          s = 0,
          r = 0;

        for (let i = x; i >= 1; i--) {
          s += parseInt(n.charAt(x - i)) * y--;
          if (y < 2) y = 9;
        }

        r = 11 - (s % 11);
        return r > 9 ? 0 : r;
      };

    return calc(t) === d1 && calc(t + 1) === d2;
  });
});

// This is meant for names (first name, last name, city)
Yup.addMethod<Yup.StringSchema>(Yup.string, 'name', function (message?: string) {
  return this.matches(/^([ -]|[A-zÀ-ú]\.?)*$/, message);
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'ccExpirationDate', function (message?: string) {
  return this.test('ccExpirationDate', message || '', (value) => {
    const validation = cardValidator.expirationDate(value);
    return validation.isValid;
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'ccNumber', function (message?: string) {
  return this.test('ccNumber', message || '', (value) => {
    if (!value) return true;
    if (value.replace(/\s/g, '').match(/^0{16}$/)) return true; // test cc
    return cardValidator.number(value.replace(/[^\d]/g, '')).isPotentiallyValid;
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'ccSecurityNumber', function (message?: string) {
  return this.test('ccSecurityNumber', message || '', (value) => {
    // return cardValidator.cvv(value).isPotentiallyValid;
    // TODO: Make it dependant from the issuer (amex allow 4)
    return !value || !!value.match(/^\d{3,4}$/);
  });
});

/**
 * Helper function to check if a select has a value selected
 */
Yup.addMethod<Yup.ObjectSchema>(Yup.object, 'oneSelected', function (message?: string) {
  return this.test('oneSelected', message || '', (value: any) => {
    return value?.value !== undefined;
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'dob', function (message?: string) {
  return this.test('dob', message || 'Formato Incorreto', (value) => {
    return (
      !value ||
      !!value.match(
        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
      )
    );
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'dateInFuture', function (message?: string) {
  return this.test('dateInFuture', message || 'Data inválida', (value) => {
    if (typeof value !== 'string') return false;
    const today = new Date();
    return today.setHours(0, 0, 0, 0) <= new Date(value).getTime();
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'fullName', function (message?: string) {
  return this.test('fullName', message || 'Nome incorreto', (value) => {
    return !value || !!value.match(/^\w+ .*$/);
  });
});

export default Yup;
