import { makeVar } from '@apollo/client';
import { Cart, Maybe, Order, SetPaymentMethodOnCartInput } from '~/operations';

export interface PlacedOrder extends Partial<Cart> {
  cart: Partial<Cart>;
  order: Maybe<Order>;
  payment: SetPaymentMethodOnCartInput;
  placedOrderTime: Date
}

export const placedOrder = makeVar<PlacedOrder | null>(null);


