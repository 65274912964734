import React, { useEffect, useState } from 'react';
import styles from '../ModalCustomerRegistrationForm.module.scss';
import {
  Col,
  Input,
  Row,
  TextfieldCommonMasks,
  Typography,
  Button,
  Form,
  Select,
} from '@objectedge/pecai-storefront-ds';
import { Controller, useForm } from 'react-hook-form';
import Yup from '~/utils/yup';
import { yupResolver } from '@hookform/resolvers';
import { useGetBuyersGroupQuery } from '~/operations';
import { optionsProps, formStateValuesProps } from '../ModalCustomerRegistrationForm';
import { Breakpoint, useBreakpoint } from '~/utils/display';

interface CompanyDataFormProps {
  handleNextFormStep: () => void;
  handleCloseModal: () => void;
  formStateValues: formStateValuesProps;
  setFormStateValues: React.Dispatch<React.SetStateAction<formStateValuesProps>>;
}

const schemaStep1 = Yup.object().shape({
  razaosocial: Yup.string().trim().required('Campo é obrigatório'),
  additionalEmail: Yup.string().trim().email('Email inválido'),
  fantasia: Yup.string().trim().required('Campo é obrigatório'),
  cnpj: Yup.string().trim().cnpj('CNPJ inválido').required('Campo é obrigatório'),
  lineBusiness: Yup.object().nullable().oneSelected('Campo é obrigatório'),
});

const CompanyDataForm: React.FC<CompanyDataFormProps> = ({
  handleNextFormStep,
  handleCloseModal,
  formStateValues,
  setFormStateValues,
}) => {
  const [buyersGroup, setBuyersGroup] = useState([] as any);
  const [lineBusiness, setLineBusiness] = useState([] as any);

  const isMiniMobileView = useBreakpoint({ max: Breakpoint.xs });
  const isMobileView = useBreakpoint({ max: Breakpoint.md });

  const { data } = useGetBuyersGroupQuery();
  useEffect(() => {
    if (data) {
      setBuyersGroup(data?.searchBuyersGroup?.edges);
      setLineBusiness(data?.searchLineBusiness?.edges);
    }
    return;
  }, [data]);

  const optionsBusinessType: optionsProps[] = lineBusiness.map((businessType) => ({
    label: businessType.name,
    value: businessType.id,
  }));

  const optionsBuyersGroup = [
    { label: 'Oficina Independente' },
    ...buyersGroup.map((buyerGroup) => ({ label: buyerGroup.name, value: buyerGroup.id })),
  ];

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ defaultValues: formStateValues, mode: 'onSubmit', resolver: yupResolver(schemaStep1) });

  const saveData = (values) => {
    setFormStateValues({ ...formStateValues, ...values });
    handleNextFormStep();
  };

  return (
    <Form onSubmit={handleSubmit(saveData)} className={styles.formContent}>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="cnpj">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              CNPJ <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            className={styles.formInput}
            register={register}
            name="cnpj"
            defaultValue={formStateValues.cnpj}
            onChange={(e) => setFormStateValues({ ...formStateValues, cnpj: e.target.value })}
            label={
              <Typography
                style={{
                  fontFamily: 'Roboto',
                }}
                variant="caption-1"
                color="gray-100"
                weight="normal"
              >
                {isMiniMobileView ? 'Digite o CNPJ' : 'Digite o CNPJ da sua empresa'}
              </Typography>
            }
            status={errors.cnpj ? 'danger' : 'default'}
            helperText={errors.cnpj?.message ?? ''}
            mask={TextfieldCommonMasks.cnpj}
            data-qa="register-cnpj"
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="razaosocial">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Razão Social <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            className={styles.formInput}
            ref={register}
            defaultValue={formStateValues.razaosocial}
            onChange={(e) => setFormStateValues({ ...formStateValues, razaosocial: e.target.value })}
            name="razaosocial"
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                {isMiniMobileView ? 'Digite a Razão Social' : 'Digite a Razão Social da sua empresa'}
              </Typography>
            }
            status={errors.razaosocial ? 'danger' : 'default'}
            helperText={errors.razaosocial?.message ?? ''}
            maxLength={40}
            data-qa="register-razaosocial"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="fantasia">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Nome Fantasia <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            className={styles.formInput}
            ref={register}
            defaultValue={formStateValues.fantasia}
            onChange={(e) => setFormStateValues({ ...formStateValues, fantasia: e.target.value })}
            name="fantasia"
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                {isMiniMobileView ? 'Digite o nome fantasia' : 'Digite o nome fantasia da sua empresa'}
              </Typography>
            }
            status={errors.fantasia ? 'danger' : 'default'}
            helperText={errors.fantasia?.message ?? ''}
            maxLength={55}
            data-qa="register-nomefantasia"
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="lineBusiness">
            <Typography
              style={{ marginBottom: '13px' }}
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Ramo de atividades <span className="text-primary">*</span>
            </Typography>
          </label>
          <Controller
            name="lineBusiness"
            control={control}
            render={({ onChange: controllerOnChange }) => (
              <Select
                name="lineBusiness"
                defaultValue={formStateValues.lineBusiness}
                isMulti={false}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  controllerOnChange(e);
                  setFormStateValues({ ...formStateValues, lineBusiness: e });
                }}
                id="multiselect-line-business"
                className={styles.formSelect}
                options={optionsBusinessType}
                status={errors.lineBusiness ? 'danger' : 'default'}
                helperText={errors.lineBusiness?.message ?? ''}
                label={
                  <Typography
                    style={{ marginTop: '6px', fontFamily: 'Roboto' }}
                    variant="caption-1"
                    color="gray-100"
                    weight="normal"
                  >
                    Ramo de atividades
                  </Typography>
                }
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="additionalEmail">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              E-mail financeiro
            </Typography>
          </label>
          <Input
            className={styles.formInput}
            ref={register}
            defaultValue={formStateValues.additionalEmail}
            onChange={(e) => setFormStateValues({ ...formStateValues, additionalEmail: e.target.value })}
            name="additionalEmail"
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                {isMiniMobileView ? 'P/ receber NFEs e boletos' : 'Para recebimento de NFEs e boletos'}
              </Typography>
            }
            status={errors.additionalEmail ? 'danger' : 'default'}
            helperText={errors.additionalEmail?.message ?? ''}
            maxLength={55}
            data-qa="register-secondemail"
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="buyersGroups">
            <Typography
              style={{ marginBottom: '13px' }}
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Rede de oficinas
            </Typography>
          </label>
          <Controller
            name="buyersGroups"
            control={control}
            render={({ onChange: controllerOnChange }) => (
              <Select
                name="buyersGroups"
                isMulti={true}
                defaultValue={formStateValues.buyersGroups}
                onChange={(e: any) => {
                  // Remove everything but last value if it's empty (Oficina Independente)
                  const lastValue = e[e.length - 1];
                  if (lastValue && !lastValue.value) e.splice(0, e.length - 1);
                  // Remove Oficina Independente if the user selected another option
                  const firstValue = e[0];
                  if (e.length > 1 && !firstValue.value) e.shift();

                  controllerOnChange(e);
                  setFormStateValues({ ...formStateValues, buyersGroups: e });
                }}
                id="multiselect-buyers-groups"
                className={styles.formSelect}
                options={optionsBuyersGroup}
                closeMenuOnSelect={false}
                fixLabel={!!formStateValues.buyersGroups}
                status={errors.buyersGroups ? 'danger' : 'default'}
                helperText={errors.buyersGroups?.message ?? ''}
                label={
                  <Typography
                    style={{ marginTop: '6px', fontFamily: 'Roboto' }}
                    variant="caption-1"
                    color="gray-100"
                    weight="normal"
                  >
                    Rede de oficinas
                  </Typography>
                }
              />
            )}
          />
        </Col>
      </Row>

      <div className={styles.buttonContainer}>
        <Button onClick={handleCloseModal} outline size="sm" color="secondary">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.00078 8.90005L2.10078 13.8C1.91745 13.9834 1.68411 14.075 1.40078 14.075C1.11745 14.075 0.884115 13.9834 0.700781 13.8C0.517448 13.6167 0.425781 13.3834 0.425781 13.1C0.425781 12.8167 0.517448 12.5834 0.700781 12.4L5.60078 7.50005L0.700781 2.60005C0.517448 2.41672 0.425781 2.18338 0.425781 1.90005C0.425781 1.61672 0.517448 1.38338 0.700781 1.20005C0.884115 1.01672 1.11745 0.925049 1.40078 0.925049C1.68411 0.925049 1.91745 1.01672 2.10078 1.20005L7.00078 6.10005L11.9008 1.20005C12.0841 1.01672 12.3174 0.925049 12.6008 0.925049C12.8841 0.925049 13.1174 1.01672 13.3008 1.20005C13.4841 1.38338 13.5758 1.61672 13.5758 1.90005C13.5758 2.18338 13.4841 2.41672 13.3008 2.60005L8.40078 7.50005L13.3008 12.4C13.4841 12.5834 13.5758 12.8167 13.5758 13.1C13.5758 13.3834 13.4841 13.6167 13.3008 13.8C13.1174 13.9834 12.8841 14.075 12.6008 14.075C12.3174 14.075 12.0841 13.9834 11.9008 13.8L7.00078 8.90005Z"
              fill="#F59D2A"
            />
          </svg>
          <span className="ml-1">Cancelar</span>
        </Button>
        <Button type="submit" size="sm">
          Próximo passo
        </Button>
      </div>
    </Form>
  );
};

export default CompanyDataForm;
