export const BestPriceIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="white" fillOpacity="0.1" />
    <path d="M21 28L27 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21.5 23C21.7761 23 22 22.7761 22 22.5C22 22.2239 21.7761 22 21.5 22C21.2239 22 21 22.2239 21 22.5C21 22.7761 21.2239 23 21.5 23Z"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5 28C26.7761 28 27 27.7761 27 27.5C27 27.2239 26.7761 27 26.5 27C26.2239 27 26 27.2239 26 27.5C26 27.7761 26.2239 28 26.5 28Z"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 20.2C17 19.6165 17.2318 19.0569 17.6443 18.6443C18.0569 18.2318 18.6165 18 19.2 18H20.2C20.7809 17.9996 21.3381 17.7696 21.75 17.36L22.45 16.66C22.6544 16.4544 22.8975 16.2912 23.1652 16.1799C23.4329 16.0685 23.72 16.0112 24.01 16.0112C24.2999 16.0112 24.587 16.0685 24.8547 16.1799C25.1225 16.2912 25.3655 16.4544 25.57 16.66L26.27 17.36C26.6819 17.7696 27.2391 17.9996 27.82 18H28.82C29.4034 18 29.963 18.2318 30.3756 18.6443C30.7882 19.0569 31.02 19.6165 31.02 20.2V21.2C31.0203 21.7809 31.2504 22.3381 31.66 22.75L32.36 23.45C32.5656 23.6544 32.7287 23.8975 32.8401 24.1652C32.9514 24.4329 33.0087 24.72 33.0087 25.01C33.0087 25.2999 32.9514 25.587 32.8401 25.8547C32.7287 26.1225 32.5656 26.3655 32.36 26.57L31.66 27.27C31.2504 27.6819 31.0203 28.2391 31.02 28.82V29.82C31.02 30.4034 30.7882 30.963 30.3756 31.3756C29.963 31.7882 29.4034 32.02 28.82 32.02H27.82C27.2391 32.0203 26.6819 32.2504 26.27 32.66L25.57 33.36C25.3655 33.5656 25.1225 33.7287 24.8547 33.8401C24.587 33.9514 24.2999 34.0087 24.01 34.0087C23.72 34.0087 23.4329 33.9514 23.1652 33.8401C22.8975 33.7287 22.6544 33.5656 22.45 33.36L21.75 32.66C21.3381 32.2504 20.7809 32.0203 20.2 32.02H19.2C18.6165 32.02 18.0569 31.7882 17.6443 31.3756C17.2318 30.963 17 30.4034 17 29.82V28.82C16.9996 28.2391 16.7696 27.6819 16.36 27.27L15.66 26.57C15.4544 26.3655 15.2912 26.1225 15.1799 25.8547C15.0685 25.587 15.0112 25.2999 15.0112 25.01C15.0112 24.72 15.0685 24.4329 15.1799 24.1652C15.2912 23.8975 15.4544 23.6544 15.66 23.45L16.36 22.75C16.7696 22.3381 16.9996 21.7809 17 21.2V20.2"
      stroke="#F49D29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_103_6415"
        x1="720"
        y1="176.941"
        x2="720"
        y2="777"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#333237" />
        <stop offset="1" stopColor="#212025" />
      </linearGradient>
    </defs>
  </svg>
);
