import React from 'react';
import { useLoginAndRegisterModal, LoginAndRegisterModalContext } from '~/utils/useLoginAndRegisterModal';

export const LoginAndRegisterModalProvider: React.FC = ({ children }) => {
  const {
    loginRegisterModalStatus,
    handleGoToLoginModal,
    handleGoToRegisterModal,
    handleCloseLoginOrRegisterModal,
    loginModalRender,
    registerModalRender,
  } = useLoginAndRegisterModal();

  return (
    <LoginAndRegisterModalContext.Provider
      value={{
        loginRegisterModalStatus,
        handleGoToLoginModal,
        handleGoToRegisterModal,
        handleCloseLoginOrRegisterModal,
        loginModalRender,
        registerModalRender,
      }}
    >
      {children}
    </LoginAndRegisterModalContext.Provider>
  );
};

export default LoginAndRegisterModalProvider;
