import { yupResolver } from '@hookform/resolvers';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  TextfieldCommonMasks,
  Typography,
} from '@objectedge/pecai-storefront-ds';
import Link from 'next/link';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GetPostalCodeDetailsDocument, GetPostalCodeDetailsQuery } from '~/operations';
import { getRegionId, stateCodes } from '~/utils/address';
import { Breakpoint, useBreakpoint } from '~/utils/display';
import { useLazyQueryWithPromise } from '~/utils/useLazyQueryWithPromise';
import Yup from '~/utils/yup';
import { formStateValuesProps } from '../ModalCustomerRegistrationForm';
import styles from '../ModalCustomerRegistrationForm.module.scss';

interface CompanyAddressFormProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  formStateValues: formStateValuesProps;
  setFormStateValues: React.Dispatch<React.SetStateAction<formStateValuesProps>>;
  handlePreviousFormStep: () => void;
  handleNextFormStep: () => void;
}

const schema = Yup.object().shape({
  addressCEP: Yup.string().trim().postalCode('CEP inválido').required('Campo é obrigatório'),
  addressStreet: Yup.string().trim().required('Campo é obrigatório'),
  addressNumber: Yup.string().trim().required('Campo é obrigatório'),
  addressComplement: Yup.string().trim(),
  addressNeighborhood: Yup.string().trim().required('Campo é obrigatório'),
  addressCity: Yup.string().trim().required('Campo é obrigatório'),
  addressState: Yup.object().nullable().oneSelected('Campo é obrigatório'),
});

const CompanyAddressForm: React.FC<CompanyAddressFormProps> = ({
  setLoading,
  handlePreviousFormStep,
  formStateValues,
  setFormStateValues,
  handleNextFormStep,
}) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: formStateValues, mode: 'onSubmit', resolver: yupResolver(schema) });
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const addrComplementMaxLength = 50;
  const [addrComplementChar, setAddrComplementChar] = useState(addrComplementMaxLength);

  const isMiniMobileView = useBreakpoint({ max: Breakpoint.xs });
  const isMobileView = useBreakpoint({ max: Breakpoint.md });

  const postalCode = watch('addressCEP');

  const fetchPostalData = useLazyQueryWithPromise<GetPostalCodeDetailsQuery>(GetPostalCodeDetailsDocument);

  const fetchPostalCodeDetails = useCallback(async () => {
    setInvalidPostalCode(false);
    const formattedPostalCode = postalCode.replace(/[^\d]/g, '');

    if (formattedPostalCode.length !== 8) return;
    try {
      setLoading(true);
      const { data } = await fetchPostalData({ variables: { postalCode: formattedPostalCode } });
      const details = data.getPostalCodeDetails;
      if (!details) {
        setInvalidPostalCode(true);
        setLoading(false);
        return;
      }
      if (details.street) {
        clearErrors('addressStreet');
        setValue('addressStreet', `${details.streetType} ${details.street}`);
      }
      if (details.neighborhood) {
        clearErrors('addressNeighborhood');
        setValue('addressNeighborhood', details.neighborhood || '');
      }
      if (details.city) {
        clearErrors('addressCity');
        setValue('addressCity', details.city || '');
      }
      if (details.stateCode) {
        clearErrors('addressState');
        setValue('addressState', {
          label: details.stateCode,
          value: getRegionId(details.stateCode),
        });
      }
      const formValues = getValues();
      setFormStateValues({
        ...formStateValues,
        addressCEP: formattedPostalCode,
        addressCity: formValues.addressCity,
        addressStreet: formValues.addressStreet,
        addressNeighborhood: formValues.addressNeighborhood,
        addressState: formValues.addressState,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [postalCode]);

  useEffect(() => {
    !!postalCode && fetchPostalCodeDetails();
  }, [postalCode]);

  useEffect(() => {
    setAddrComplementChar(addrComplementMaxLength - formStateValues.addressComplement.length);
  }, []);

  const addressStreetValue = watch('addressStreet');
  const addressNeighborhoodValue = watch('addressNeighborhood');
  const addressCityValue = watch('addressCity');
  const addressStateValue = watch('addressState');

  const handleComplementOnChange = (e) => {
    const { value } = e.target;
    setAddrComplementChar(addrComplementMaxLength - value.length);
    setFormStateValues({ ...formStateValues, addressComplement: value });
  };

  // Show character counter
  const renderCharCounter = () => {
    return `${addrComplementMaxLength - addrComplementChar}/${addrComplementMaxLength}`;
  };

  const saveData = (values) => {
    setFormStateValues({ ...formStateValues, ...values });
    handleNextFormStep();
  };

  return (
    <Form onSubmit={handleSubmit(saveData)} className={styles.formContent}>
      <Row>
        <Col lg={6}>
          <div className={styles.labelCepContainer}>
            <label className={styles.inputLabelCep} htmlFor="addressCEP">
              <Typography
                variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
                color="primary-900"
              >
                CEP <span className="text-primary">*</span>
              </Typography>
            </label>
            <Typography variant={isMiniMobileView ? 'caption-2' : 'caption-1'} weight="light">
              Não sabe seu CEP?
              <Link href="https://buscacepinter.correios.com.br/app/endereco/index.php" passHref>
                <a target="_blank"> clique aqui</a>
              </Link>
            </Typography>
          </div>
          <Input
            register={register}
            name="addressCEP"
            defaultValue={formStateValues.addressCEP}
            onChange={(e) => setFormStateValues({ ...formStateValues, addressCEP: e.target.value })}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite seu CEP
              </Typography>
            }
            fixLabel={!!postalCode}
            mask={TextfieldCommonMasks.cep}
            status={errors.addressCEP || invalidPostalCode ? 'danger' : 'default'}
            helperText={(errors.addressCEP?.message || (invalidPostalCode && 'CEP Inválido')) ?? ''}
            className={styles.formInput}
            data-qa="register-cep"
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="addressStreet">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Endereço <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            ref={register}
            name="addressStreet"
            defaultValue={formStateValues.addressStreet}
            onChange={(e) => setFormStateValues({ ...formStateValues, addressStreet: e.target.value })}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite seu endereço
              </Typography>
            }
            fixLabel={!!addressStreetValue}
            status={errors.addressStreet ? 'danger' : 'default'}
            helperText={errors.addressStreet?.message ?? ''}
            maxLength={255}
            className={styles.formInput}
            data-qa="register-address"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="addressNumber">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Número <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            ref={register}
            name="addressNumber"
            defaultValue={formStateValues.addressNumber}
            onChange={(e) => setFormStateValues({ ...formStateValues, addressNumber: e.target.value })}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Número do estabelecimento
              </Typography>
            }
            status={errors.addressNumber ? 'danger' : 'default'}
            helperText={errors.addressNumber?.message ?? ''}
            maxLength={15}
            className={styles.formInput}
            data-qa="register-number"
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="addressComplement">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Complemento
            </Typography>
          </label>
          <Input
            ref={register}
            name="addressComplement"
            defaultValue={formStateValues.addressComplement}
            onChange={handleComplementOnChange}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Complemento do endereço
              </Typography>
            }
            maxLength={50}
            renderCharCounter={renderCharCounter()}
            className={styles.formInput}
            data-qa="register-complement"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={5}>
          <label className={styles.inputLabel} htmlFor="addressNeighborhood">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Bairro <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            ref={register}
            name="addressNeighborhood"
            defaultValue={formStateValues.addressNeighborhood}
            onChange={(e) => setFormStateValues({ ...formStateValues, addressNeighborhood: e.target.value })}
            fixLabel={!!addressNeighborhoodValue}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite seu bairro
              </Typography>
            }
            status={errors.addressNeighborhood ? 'danger' : 'default'}
            helperText={errors.addressNeighborhood?.message ?? ''}
            maxLength={50}
            className={styles.formInput}
            data-qa="register-neighborhood"
          />
        </Col>
        <Col lg={5}>
          <label className={styles.inputLabel} htmlFor="addressCity">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Cidade <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            ref={register}
            name="addressCity"
            defaultValue={formStateValues.addressCity}
            onChange={(e) => setFormStateValues({ ...formStateValues, addressCity: e.target.value })}
            fixLabel={!!addressCityValue}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite sua cidade
              </Typography>
            }
            status={errors.addressCity ? 'danger' : 'default'}
            helperText={errors.addressCity?.message ?? ''}
            maxLength={50}
            className={styles.formInput}
            data-qa="register-city"
          />
        </Col>
        <Col lg={2}>
          <label className={styles.inputLabel} htmlFor="addressState">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              UF <span className="text-primary">*</span>
            </Typography>
          </label>
          <Controller
            control={control}
            as={<Select dataqa="register-uf" />}
            name="addressState"
            defaultValue={formStateValues.addressState}
            onChange={(e) => {
              setFormStateValues({ ...formStateValues, addressState: e.target.value });
            }}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                UF
              </Typography>
            }
            fixLabel={!!addressStateValue}
            options={stateCodes}
            status={errors.addressState ? 'danger' : 'default'}
            helperText={errors.addressState?.message ?? ''}
            className={styles.formSelect}
          />
        </Col>
      </Row>
      <div className={styles.buttonContainer}>
        <Button onClick={handlePreviousFormStep} size="sm" color="secondary" outline>
          Voltar
        </Button>
        <Button type="submit" size="sm">
          Próximo passo
        </Button>
      </div>
    </Form>
  );
};

export default CompanyAddressForm;
