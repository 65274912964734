import { yupResolver } from '@hookform/resolvers';
import { Button, Color, Form, Input, Typography } from '@objectedge/pecai-storefront-ds';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckCircle } from '~/components/icons';
import { useSendResetPasswordMutation } from '~/operations';
import { validation } from '~/utils/customer';
import { useNotification } from '~/utils/useNotification';
import styles from './ResetPasswordForm.module.scss';

interface ResetPasswordFormProps {
  changeFormState: () => void;
  handleSuccessLayout: () => void;
}

const schema = validation.getSchema({ email: 1 });

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ changeFormState, handleSuccessLayout }) => {
  const notify = useNotification();
  const [resetting, setResetting] = useState(false);
  const [email, setEmail] = useState<string>();
  const [resetPassword] = useSendResetPasswordMutation();
  const { register, handleSubmit, setError, errors } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async ({ email }) => {
    setResetting(true);
    try {
      const result = await resetPassword({ variables: { email } });
      if (result?.data?.backofficeSendResetPassword?.success) {
        setEmail(email);
        handleSuccessLayout();
        return;
      }

      setError('email', { message: 'O usuário com e-mail informado não existe' });
    } catch (e) {
      notify.error('Um erro ocorreu, por favor tente mais tarde');
    }

    setResetting(false);
  };

  const handleGetBackToLoginModal = () => {
    handleSuccessLayout();
    changeFormState();
  };

  return (
    <div className={styles.resetForm}>
      {!!email && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div className={styles.checkCircle}>
            <CheckCircle />
          </div>
          <Button onClick={handleGetBackToLoginModal} block color={Color.PRIMARY}>
            Faça seu login
          </Button>
        </div>
      )}

      {!email && (
        <>
          <Form className={styles.formContent} onSubmit={handleSubmit(onSubmit)}>
            <label className={styles.inputLabel} htmlFor="email">
              <Typography variant="button-large" color="primary-900" weight="bold">
                E-mail
              </Typography>
            </label>
            <Input
              autoFocus
              ref={register}
              name="email"
              label="Digite seu e-mail"
              className={styles.emailInput}
              status={errors.email ? 'danger' : 'default'}
              helperText={errors.email?.message ?? ''}
              disabled={resetting}
              defaultValue={email}
              data-qa="recover-email"
            />
            <Typography className={styles.bottomLabel} style={{}} color="gray-100" variant="body-small">
              Uma nova senha será enviada para o e-mail acima
            </Typography>
            <Button data-qa="recover-button" type="submit" block disabled={resetting}>
              Enviar
            </Button>
          </Form>
          <Button color="primary" className={styles.backToLogin} outline onClick={changeFormState}>
            Voltar
          </Button>
        </>
      )}
    </div>
  );
};

export default ResetPasswordForm;
