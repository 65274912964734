import { InMemoryCache, TypePolicies } from '@apollo/client';

const typePolicies: TypePolicies = {
  CatalogFilter: {
    keyFields: false,
  },
  Spec: {
    keyFields: false,
  },
  AvailablePaymentMethod: {
    keyFields: ['code'],
  },
};

const possibleTypes = {
  CartAddressInterface: ['BillingCartAddress', 'ShippingCartAddress'],
  CartItemInterface: [
    'SimpleCartItem',
    'VirtualCartItem',
    'DownloadableCartItem',
    'BundleCartItem',
    'ConfigurableCartItem',
  ],
  ProductInterface: [
    'VirtualProduct',
    'SimpleProduct',
    'DownloadableProduct',
    'BundleProduct',
    'GroupedProduct',
    'ConfigurableProduct',
  ],
  CategoryInterface: ['CategoryTree'],
  MediaGalleryInterface: ['ProductImage', 'ProductVideo'],
  ProductLinksInterface: ['ProductLinks'],
  CreditMemoItemInterface: ['DownloadableCreditMemoItem', 'BundleCreditMemoItem', 'CreditMemoItem'],
  OrderItemInterface: ['DownloadableOrderItem', 'BundleOrderItem', 'OrderItem'],
  InvoiceItemInterface: ['DownloadableInvoiceItem', 'BundleInvoiceItem', 'InvoiceItem'],
  ShipmentItemInterface: ['BundleShipmentItem', 'ShipmentItem'],
  AggregationOptionInterface: ['AggregationOption'],
  LayerFilterItemInterface: ['LayerFilterItem', 'SwatchLayerFilterItem'],
  PhysicalProductInterface: ['SimpleProduct', 'BundleProduct', 'GroupedProduct', 'ConfigurableProduct'],
  CustomizableOptionInterface: [
    'CustomizableAreaOption',
    'CustomizableDateOption',
    'CustomizableDropDownOption',
    'CustomizableMultipleOption',
    'CustomizableFieldOption',
    'CustomizableFileOption',
    'CustomizableRadioOption',
    'CustomizableCheckboxOption',
  ],
  CustomizableProductInterface: [
    'VirtualProduct',
    'SimpleProduct',
    'DownloadableProduct',
    'BundleProduct',
    'ConfigurableProduct',
  ],
  SwatchDataInterface: ['ImageSwatchData', 'TextSwatchData', 'ColorSwatchData'],
  SwatchLayerFilterItemInterface: ['SwatchLayerFilterItem'],
  BasePayload: [
    'LoginMutationPayload',
    'RegisterMutationPayload',
    'ResetPasswordMutationPayload',
    'SendResetPasswordMutationPayload',
    'UpdateUserMutationPayload',
    'UserMutationPayload',
  ],
  Profile: ['TunneStoreProfile', 'TunnePartnerProfile'],
  BasePayloadScheduling: [
    'AppointmentPayload',
    'CalendarMutationPayload',
    'RemoveAppointmentPayload',
    'ScheduleAppointmentPayload',
    'ServiceIntervalPayload',
    'UpdateAppointmentStatusPayload',
  ],
  ChangeLog: ['AddressChangeLog', 'DateChangeLog', 'StatusChangeLog'],
  BasePayloadLocation: ['CleanUpStaleDataPayload'],
  Payment: ['BoletoPayment', 'CreditCardPayment'],
  PaymentChangelog: ['AmountChangelog', 'CreationChangelog', 'RefundChangelog', 'StatusChangelog'],
  CatalogProductInterface: ['CatalogProduct', 'CatalogProductDetails'],
  Node: ['ServiceArea'],
};

export default new InMemoryCache({
  typePolicies,
  possibleTypes,
});
