import { makeVar, useReactiveVar } from '@apollo/client';
import { createContext, useContext } from 'react';

const alertsState = makeVar<Alert[]>([]);

type AlertColor = 'light' | 'danger' | 'success' | 'warning';

type Alert = {
  id: number;
  text: string;
  color: AlertColor;
};

type Actions = {
  addSuccessAlert: (text: string, delay?: number) => void;
  addDangerAlert: (text: string, delay?: number) => void;
  addWarningAlert: (text: string, delay?: number) => void;
  removeAlert: (id: number, delay?: number) => void;
  removeAllAlerts: () => void;
};

export const useAlerts = () => {
  const state = useReactiveVar(alertsState);

  const removeAlert = (id: number) => {
    alertsState(state.filter((a) => a.id === id));
  };

  const removeAllAlerts = () => {
    alertsState([]);
    state.length = 0;
  };

  const addAlert = (color: AlertColor) => (text: string, delay: number = 5000) => {
    const id = Date.now();
    alertsState([...state, { id, text, color }]);
    setTimeout(() => removeAlert(id), delay);
  };

  return {
    state,
    actions: {
      addSuccessAlert: addAlert('success'),
      addDangerAlert: addAlert('danger'),
      addWarningAlert: addAlert('warning'),
      removeAlert,
      removeAllAlerts,
    },
  };
};

export const AlertsContext = createContext<{ state: Alert[]; actions: Actions }>({
  state: [],
  actions: {
    addSuccessAlert: (text: string, delay?: number) => {},
    addDangerAlert: (text: string, delay?: number) => {},
    addWarningAlert: (text: string, delay?: number) => {},
    removeAlert: (id: number, delay?: number) => {},
    removeAllAlerts: () => {},
  },
});

export const useAlertsContext = () => useContext(AlertsContext);
