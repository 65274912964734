import React, { createContext } from 'react';
import { LoginModal } from '~/components/authentication/LoginModal';
import RegisterModal from '~/components/forms/RegisterModal';
import { makeVar, useReactiveVar } from '@apollo/client';

export enum ModalOpenEnum {
  NO_MODAL = 0,
  LOGIN_MODAL = 1,
  REGISTER_MODAL = 2,
}

interface LoginAndRegisterModalProps {
  loginRegisterModalStatus: number;
  handleGoToLoginModal(): void;
  handleGoToRegisterModal(): void;
  handleCloseLoginOrRegisterModal(): void;
  loginModalRender(): any;
  registerModalRender(): any;
}

const loginRegisterModalStatusVar = makeVar<ModalOpenEnum>(0);

export const LoginAndRegisterModalContext = createContext<LoginAndRegisterModalProps>({} as LoginAndRegisterModalProps);

export const useLoginAndRegisterModal = () => {
  const loginRegisterModalStatus = useReactiveVar(loginRegisterModalStatusVar);

  const handleGoToLoginModal = () => {
    loginRegisterModalStatusVar(ModalOpenEnum.LOGIN_MODAL);
  };

  const handleGoToRegisterModal = () => {
    loginRegisterModalStatusVar(ModalOpenEnum.REGISTER_MODAL);
  };

  const handleCloseLoginOrRegisterModal = () => {
    loginRegisterModalStatusVar(ModalOpenEnum.NO_MODAL);
  };

  const loginModalRender = () => (
    <LoginModal
      isOpen={loginRegisterModalStatus === ModalOpenEnum.LOGIN_MODAL}
      cancelHandler={() => loginRegisterModalStatusVar(0)}
    />
  );

  const registerModalRender = () => (
    <RegisterModal
      handleGoToLoginModal={handleGoToLoginModal}
      isOpen={loginRegisterModalStatus === ModalOpenEnum.REGISTER_MODAL}
      cancelHandler={() => loginRegisterModalStatusVar(0)}
    />
  );

  return {
    loginRegisterModalStatus,
    handleGoToLoginModal,
    handleGoToRegisterModal,
    handleCloseLoginOrRegisterModal,
    loginModalRender,
    registerModalRender,
  };
};
