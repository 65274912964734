export var Color;
(function(Color) {
    Color["PRIMARY"] = "primary";
    Color["PRIMARY_LIGHT"] = "primaryLight";
    Color["PRIMARY_PRESSED"] = "primary-pressed";
    Color["PRIMARY_HOVER"] = "primary-hover";
    Color["SECONDARY"] = "secondary";
    Color["SECONDARY_PRESSED"] = "secondary-pressed";
    Color["SECONDARY_HOVER"] = "secondary-hover";
    Color["SUCCESS"] = "success";
    Color["SUCCESS_PRESSED"] = "success-pressed";
    Color["SUCCESS_HOVER"] = "success-hover";
    Color["INFO"] = "info";
    Color["INFO_PRESSED"] = "infoPressed";
    Color["INFO_HOVER"] = "infoHover";
    Color["WARNING"] = "warning";
    Color["WARNING_PRESSED"] = "warning-pressed";
    Color["WARNING_HOVER"] = "warning-hover";
    Color["DANGER"] = "danger";
    Color["DANGER_PRESSED"] = "danger-pressed";
    Color["DANGER_HOVER"] = "danger-hover";
    Color["LIGHT"] = "light";
    Color["LIGHT_PRESSED"] = "light-pressed";
    Color["LIGHT_HOVER"] = "light-hover";
    Color["DARK"] = "dark";
    Color["DARK_HOVER"] = "lightHover";
    Color["LINK"] = "link";
    Color["LINK_PRESSED"] = "linkPressed";
    Color["LINK_HOVER"] = "linkHover";
    Color["ACCENT"] = "accent";
    Color["LINKS"] = "links";
    Color["DEFAULT"] = "default";
    Color["BLACK"] = "black";
    Color["GREY"] = "grey";
    Color["LIGHT_GREY"] = "light-grey";
    Color["WHITE"] = "white";
    Color["PRIMARY_900"] = "primary-900";
    Color["PRIMARY_800"] = "primary-800";
    Color["PRIMARY_700"] = "primary-700";
    Color["PRIMARY_600"] = "primary-600";
    Color["PRIMARY_500"] = "primary-500";
    Color["PRIMARY_400"] = "primary-400";
    Color["PRIMARY_300"] = "primary-300";
    Color["PRIMARY_200"] = "primary-200";
    Color["PRIMARY_100"] = "primary-100";
    Color["SECONDARY_900"] = "secondary-900";
    Color["SECONDARY_800"] = "secondary-800";
    Color["SECONDARY_700"] = "secondary-700";
    Color["SECONDARY_600"] = "secondary-600";
    Color["SECONDARY_500"] = "secondary-500";
    Color["SECONDARY_400"] = "secondary-400";
    Color["SECONDARY_300"] = "secondary-300";
    Color["SECONDARY_200"] = "secondary-200";
    Color["SECONDARY_100"] = "secondary-100";
    Color["GRAY_500"] = "gray-500";
    Color["GRAY_400"] = "gray-400";
    Color["GRAY_300"] = "gray-300";
    Color["GRAY_200"] = "gray-200";
    Color["GRAY_100"] = "gray-100";
    Color["GRAY_50"] = "gray-50";
})(Color || (Color = {}));
export var Size;
(function(Size) {
    Size["SM"] = "sm";
    Size["LG"] = "lg";
    Size["MD"] = "md";
})(Size || (Size = {}));
export var Placement;
(function(Placement) {
    Placement["AUTO"] = "auto";
    Placement["AUTO_START"] = "auto-start";
    Placement["AUTO_END"] = "auto-end";
    Placement["TOP"] = "top";
    Placement["TOP_START"] = "top-start";
    Placement["TOP_END"] = "top-end";
    Placement["RIGHT"] = "right";
    Placement["RIGHT_START"] = "right-start";
    Placement["RIGHT_END"] = "right-end";
    Placement["BOTTOM"] = "bottom";
    Placement["BOTTOM_START"] = "bottom-start";
    Placement["BOTTOM_END"] = "bottom-end";
    Placement["LEFT"] = "left";
    Placement["LEFT_START"] = "left-start";
    Placement["LEFT_END"] = "left-end";
})(Placement || (Placement = {}));
