import { useState } from 'react';
import { GetPostalCodeDetailsDocument, GetPostalCodeDetailsQuery, PostalCodeDetails } from '~/operations';
import { useLazyQueryWithPromise } from './useLazyQueryWithPromise';

export const stateCodes = [
  { label: 'AC', value: '485' },
  { label: 'AL', value: '486' },
  { label: 'AP', value: '487' },
  { label: 'AM', value: '488' },
  { label: 'BA', value: '489' },
  { label: 'CE', value: '490' },
  { label: 'ES', value: '491' },
  { label: 'GO', value: '492' },
  { label: 'MA', value: '493' },
  { label: 'MT', value: '494' },
  { label: 'MS', value: '495' },
  { label: 'MG', value: '496' },
  { label: 'PA', value: '497' },
  { label: 'PB', value: '498' },
  { label: 'PR', value: '499' },
  { label: 'PE', value: '500' },
  { label: 'PI', value: '501' },
  { label: 'RJ', value: '502' },
  { label: 'RN', value: '503' },
  { label: 'RS', value: '504' },
  { label: 'RO', value: '505' },
  { label: 'RR', value: '506' },
  { label: 'SC', value: '507' },
  { label: 'SP', value: '508' },
  { label: 'SE', value: '509' },
  { label: 'TO', value: '510' },
  { label: 'DF', value: '511' },
];

export const getRegion = (state: string) => {
  return stateCodes.find((s) => s.label === state);
};

export const getRegionId = (state: string) => {
  let region = stateCodes.find((s) => s.label === state);

  return region ? region.value : '508'; // sp
};


export const useFetchPostalCode = () => {
  const fetchPostalCode = useLazyQueryWithPromise<GetPostalCodeDetailsQuery>(GetPostalCodeDetailsDocument);
  const [loading, setLoading] = useState(false);

  const fetch = (code = '') =>
    new Promise<Partial<PostalCodeDetails>>((res, rej) => {
      setLoading(true);
      fetchPostalCode({ variables: { postalCode: code.replace(/[^\d]/g, '') } })
        .then(({ data }) => {
          if (data?.getPostalCodeDetails) res(data.getPostalCodeDetails);
          else rej();
        })
        .catch(() => rej())
        .finally(() => setLoading(false));
    });

  return { fetch, loading };
};
