export const CheckCircle = () => (
  <svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M104.5 52.6303V57.0003C104.494 67.2433 101.177 77.21 95.0443 85.414C88.9112 93.618 80.2905 99.6196 70.4679 102.524C60.6452 105.428 50.1469 105.079 40.5387 101.53C30.9304 97.9799 22.727 91.4193 17.152 82.8264C11.577 74.2335 8.92895 64.0686 9.6029 53.8478C10.2769 43.627 14.2367 33.8979 20.8918 26.1114C27.5469 18.325 36.5407 12.8984 46.5318 10.641C56.523 8.38365 66.9762 9.41643 76.3325 13.5853"
      stroke="#333338"
      strokeWidth="9.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.5 19L57 66.5475L42.75 52.2975"
      stroke="#FBA93E"
      strokeWidth="9.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
