import { makeVar } from '@apollo/client';

export interface ConfirmationConfigs {
  cancelLabel?: string;
  okLabel?: string;
  title?: string;
  size?: 'sm' | 'md' | 'lg';
}

export interface UseConfirmationState {
  message?: string;
  configs?: ConfirmationConfigs;
  resolve: AnyFunction;
}

export const confirmationState = makeVar<UseConfirmationState | null>(null);

export const useConfirmation = () => {
  const confirm = (message: string, configs: ConfirmationConfigs = {}) =>
    new Promise((resolve) => {
      confirmationState({ message, configs, resolve });
    }).finally(() => confirmationState(null));

  return confirm;
};
