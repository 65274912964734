import { createContext } from 'react';

interface EventInterface {
  pub: (event: Event, data?: any) => void;
  sub: (event: Event, callback: Function) => () => void;
}

export enum Event {
  APOLLO_UNAUTHORIZED_ACCESS,
  LOGIN,
  LOGOUT,
  SET_PROVIDER_ID,
}

type Subscriber = {
  event: Event;
  callback: (data?: any) => any;
};

export const EventContext = createContext<EventInterface>({} as any);

const subscribers = new Set<Subscriber>();

export const EventProvider: React.FC = ({ children }) => {
  const pub = (event: Event, data: any) => {
    for (const sub of subscribers) {
      if (sub.event === event) sub.callback(data);
    }
  };

  const sub = (event, callback) => {
    const subscriber = { event, callback };
    subscribers.add(subscriber);

    return () => subscribers.delete(subscriber); // unsubscribe
  };

  return <EventContext.Provider value={{ pub, sub }}>{children}</EventContext.Provider>;
};
