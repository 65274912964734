import _define_property from "@swc/helpers/src/_define_property.mjs";
import { makeStyles, createTheme } from "@material-ui/core/styles";
export var styles = function(theme) {
    return {
        cropContainer: _define_property({
            position: "relative",
            width: "100%",
            height: 200,
            background: "#333"
        }, theme.breakpoints.up("sm"), {
            height: 400
        }),
        cropButton: {
            flexShrink: 0,
            marginLeft: 16
        },
        controls: _define_property({
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch"
        }, theme.breakpoints.up("sm"), {
            flexDirection: "row",
            alignItems: "center"
        }),
        sliderContainer: {
            display: "flex",
            flex: "1",
            alignItems: "center"
        },
        sliderLabel: _define_property({
            marginRight: 10,
            marginLeft: 5
        }, theme.breakpoints.down("xs"), {
            minWidth: 65
        }),
        slider: _define_property({
            padding: "22px 0px",
            marginLeft: 16,
            marginRight: 5,
            color: "#f49d29"
        }, theme.breakpoints.up("sm"), {
            flexDirection: "row",
            alignItems: "center",
            margin: "0 16px"
        })
    };
};
export var dialogStyles = makeStyles({
    appBar: {
        position: "relative",
        backgroundColor: "#f49d29"
    },
    flex: {
        flex: 1
    },
    imgContainer: {
        position: "relative",
        flex: 1,
        padding: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    img: {
        maxWidth: "100%",
        maxHeight: "100%"
    }
});
export var sliderTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: 50,
                width: 15,
                height: 15,
                marginTop: -7
            },
            track: {
                color: "#000",
                height: 1
            },
            rail: {
                height: 1,
                backgroundColor: "#000"
            }
        }
    }
});
