import { useReactiveVar } from '@apollo/client';
import { FC } from 'react';
import { Modal } from '@objectedge/pecai-storefront-ds';
import { ConfirmationConfigs, confirmationState } from '~/utils/useConfirmation';

const defaultConf: ConfirmationConfigs = {
  cancelLabel: 'Cancelar',
  okLabel: 'Confirmar',
  size: 'sm',
};

export const ConfirmationProvider: FC = ({ children }) => {
  const data = useReactiveVar(confirmationState);
  const configs = { ...defaultConf, ...(data?.configs || {}) };

  const actionHandler = (arg: Boolean, callback?: AnyFunction) => () => callback && callback(arg);

  return (
    <>
      <Modal
        isOpen={!!data}
        showModalHeader={!!configs.title}
        title={configs.title}
        okHandler={actionHandler(true, data?.resolve)}
        cancelHandler={actionHandler(false, data?.resolve)}
        okButtonLabel={configs.okLabel}
        cancelButtonLabel={configs.cancelLabel}
        size={configs.size}
      >
        {data?.message}
      </Modal>
      {children}
    </>
  );
};

export default ConfirmationProvider;
