import React, { useEffect, useState } from 'react';

export function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = () => ({
    width: hasWindow ? window.innerWidth : null,
    height: hasWindow ? window.innerHeight : null,
  });

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (!hasWindow) return;
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [hasWindow]);

  return windowDimensions;
}

const breakpoints = { xxs: 280, xs: 400, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1440 };

export enum Breakpoint {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export interface DisplayProps {
  min?: Breakpoint;
  max?: Breakpoint;
}

export const getBreakpoint = (bp: Breakpoint) => breakpoints[bp];

export function useBreakpoint({ min, max }: DisplayProps): boolean {
  const { width } = useWindowDimensions();

  const w = width || 0;
  const minW = min ? breakpoints[min] : 0;
  const maxW = max ? breakpoints[max] : Infinity;
  const display = w > minW && w <= maxW;

  return display;
}

export const Display: React.FC<DisplayProps> = ({ min, max, children }) => {
  const display = useBreakpoint({ min, max });

  return display ? <>{children}</> : null;
};
