import { Alert } from '@objectedge/pecai-storefront-ds';
import { useAlertsContext } from '~/utils/useAlerts';
import styles from './Alerts.module.scss';

export const Alerts = () => {
  const { state: alerts } = useAlertsContext();

  return (
    <div className={styles['alert-container']}>
      {alerts.map((alert) => (
        <Alert color={alert.color} key={alert.id}>
          {alert.text}
        </Alert>
      ))}
    </div>
  );
};
