import { useApolloClient } from '@apollo/client';

/**
 * Apollo client useLazyQuery does not return a promise. This function is a
 * workaround to execute lazy queries that return one, and therefore
 * can be used in async/await context
 *
 * @param query
 */
export function useLazyQueryWithPromise<T>(query) {
  const client = useApolloClient();

  return (props = {}) => client.query<T>({ query, ...props });
}
