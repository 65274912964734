import cookie from 'js-cookie';

const COOKIE_KEY = process.env.COOKIE_KEY || 'partnerToken';
const domain = process.env.COOKIE_DOMAIN;

const splitStringByBytes = (str, chunkSize) => {
  let chunks: string[] = [];
  let currentChunk = '';
  let currentChunkSize = 0;
  for (let i = 0; i < str.length; i++) {
    let char = str[i];
    let charSize = 0;
    if (char.charCodeAt(0) < 0x10000) charSize = 2;
    else charSize = 4;
    if (currentChunkSize + charSize > chunkSize) {
      chunks.push(currentChunk);
      currentChunk = char;
      currentChunkSize = charSize;
    } else {
      currentChunk += char;
      currentChunkSize += charSize;
    }
  }
  if (currentChunk.length > 0) chunks.push(currentChunk);
  return chunks;
};

const getCookieKey = (i) => COOKIE_KEY + (i ? '_' + i : '');

export const getAuthCookie = () => {
  let i = 0;
  let value = '';
  while (true) {
    const piece = cookie.get(getCookieKey(i++));
    if (piece) value += piece;
    else break;
  }
  return value || undefined;
};

export const setAuthCookie = (token?: string) => {
  if (!token) {
    Array.from(Array(10).keys()).forEach((i) => cookie.remove(getCookieKey(i), { domain }));
    return;
  }

  const chunks = splitStringByBytes(token, 4096);
  let i = 0;
  for (let c of chunks) {
    cookie.set(getCookieKey(i), c, { domain });
    i += 1;
  }
};
